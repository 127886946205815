.buttonPadding {
    padding: 0 var(--spacing-2);
    gap: 0;
}

.loginText {
    padding: 0 0 0 var(--spacing-2);
    display: none;

    @media --md {
        display: block;
    }
}