.iconBadge {
    border-radius: var(--font-size-2xs);
    position: absolute;
    top: -6px;
    left: -3px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-2xs);
    line-height: var(--line-height-single); /* Added padding to the height as min-width */
    min-width: calc(var(--font-size-2xs) + 8px); /* Added padding to the height as min-width */
    background-color: var(--color-neutral-black);
    color: var(--color-neutral-white);
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 4px;
}

.buttonPadding {
    padding: 0 var(--spacing-2);
    gap: 0;
}